import React from 'react'
import { ourMission1 } from '../assets'

function OurMission() {
    return (
        <div className={`bg-[#F5F2F2] pb-10`}>
            <div className='grid h-full grid-cols-1 gap-8 px-8 py-8 md:py-0 md:grid-cols-2 xs:px-16'>
                <div className='flex flex-col justify-center h-full col-span-1 gap-6 xs:gap-10 lg:pl-44'>
                    <h2 className='font-open_sans font-bold text-[32px] xs:text-[40px] leading-[54.47px]'>
                        Our Mission
                    </h2>
                    <div className='flex flex-col gap-4'>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            To empower businesses with an intelligent and seamless invoice verification system that
                            enhances accuracy, eliminates discrepancies, and accelerates the verification process.
                        </p>
                        <p className='text-base font-normal text-justify xs:text-xl font-open_sans'>
                            We aim to utilize AI not only to transform the invoice verification process but
                            also to deliver significant business benefits, such as reduced operational costs,
                            improved productivity, and enhanced decision-making.
                        </p>
                    </div>
                </div>
                <div className='hidden col-span-1 mt-10 md:block'>
                    <img src={ourMission1} className='' alt='image1' />
                </div>
            </div>
        </div>
    )
}

export default OurMission