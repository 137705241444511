import React from 'react'
import { choice1, choice2, choice3, choice4, choice5, choice6 } from '../assets';
import styles from '../style';

const Cards = [
  {
    info: 'Accelerates your verification process, reducing the time it takes to cross-check invoices against rate cards.',
    icon: choice1,
  },
  {
    info: 'Ensures that every transaction aligns with your predetermined rates, preventing financial losses due to billing inaccuracies',
    icon: choice2,
  },
  {
    info: 'Scales with you to adapt to your growing needs.',
    icon: choice3,
  },
  {
    info: 'Prioritizes data security, employing advanced encryption protocols to safeguard all sensitive information throughout the verification process.',
    icon: choice4,
  },
  {
    info: 'Effortlessly navigate with a user-friendly interface.',
    icon: choice5,
  },
  {
    info: 'Track trends, identify outliers, and make informed decisions to optimize your operations further.',
    icon: choice6,
  },
];

function WhyIverifai() {
  return (
    <div className={`bg-white ${styles.paddingX} py-10`}>
      <h3 className='font-bold font-open_sans text-[40px] text-center leading-[54.47px]'>
        Why Choose iVerifAI?
      </h3>
      <div className='grid grid-cols-1 px-2 py-8 lg:px-40 gap-x-12 gap-y-12 sm:grid-cols-2 md:grid-cols-3'>
        {Cards.map((item, index) => (
          <div key={index} className={`col-span-1 px-6 py-6 bg-[#033469] rounded-md`}>
            <div className='flex justify-center h-[90px]'>
              <img src={item.icon} alt='img2' />
            </div>
            <div>
              <p className={`font-open_sans font-semibold text-[18px] leading-[24px] text-white pt-6`}>
                {item.info}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WhyIverifai