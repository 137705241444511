import React from 'react'
import { marker, mail, phone, facebook, instagram, twitter, linkedin } from '../assets'

function Footer() {
  return (
    <div>
      <div
        className='grid grid-cols-1 px-8 py-4 md:px-24 md:grid-cols-3 gap-y-4 gap-x-12'
        style={{ backgroundImage: 'linear-gradient(-60deg, #0E7CF3 39.5%, #0D5DB4 39.5%)' }}
      >
        <div className='flex items-center col-span-1 gap-4'>
          <div className='px-4 py-1 bg-white md:px-3'>
            <img src={marker} alt='img2' />
          </div>
          <div className='text-lg font-bold text-white font-open_sans'>
            <p>Location</p>
            <p>6391 Elgin St. Celina, 10299</p>
          </div>
        </div>
        <div className='flex items-center col-span-1 gap-4'>
          <div className='px-2 py-2 bg-white'>
            <img src={mail} alt='img2' />
          </div>
          <div className='text-lg font-bold text-white font-open_sans'>
            <p>Email</p>
            <p>info@iverifai.com</p>
          </div>
        </div>
        <div className='flex items-center col-span-1 gap-4'>
          <div className='px-3 py-1 bg-white md:px-2'>
            <img src={phone} alt='img2' />
          </div>
          <div className='text-lg font-bold text-white font-open_sans'>
            <p>Call us on</p>
            <p>925 674 5678</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col px-8 py-6 pb-10 bg-white gap-y-6 sm:flex-row gap-x-16 lg:px-48 justify-evenly'>
        <div className='flex items-center justify-center'>
          <p className='text-lg font-semibold font-open_sans'>
            Our Customer service team is waiting here to assist you.We’d love to hear from you
          </p>
        </div>
        <div className='min-w-24'>
          <div>
            <h4 className='font-open_sans font-bold text-[22px] leading-[26.63px] '>Links</h4>
          </div>
          <div className='pt-2 md:pt-6'>
            <ul>
              <li className={`text-base font-semibold font-open_sans cursor-pointer`}>
                <a href={`/about`}>
                  About Us
                </a>
              </li>
              <li className={`text-base py-2 md:py-4 font-semibold font-open_sans cursor-pointer`}>
                <a href={`#blogs`}>
                  Blogs
                </a>
              </li>
              <li className={`text-base font-semibold font-open_sans cursor-pointer`}>
                <a href={`#contact-us`}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <h4 className='font-open_sans font-bold text-[22px] leading-[26.63px] '>
              Contact Us
            </h4>
          </div>
          <div className='py-2 md:py-6'>
            <p className='text-lg font-semibold pr-9 font-open_sans'>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
          <div>
            <h5 className='text-lg font-semibold font-open_sans'>
              +908 89097 890
            </h5>
          </div>
        </div>
        <div className='flex items-end gap-3 justify-evenly'>
          <div className='px-3 py-3 w-[35px] h-[35px] rounded-full shadow-md'>
            <img src={facebook} alt='facebook' />
          </div>
          <div className='px-3 py-3 w-[35px] h-[35px] rounded-full shadow-md'>
            <img src={instagram} alt='instagram' />
          </div>
          <div className='px-3 py-3 w-[35px] h-[35px] rounded-full shadow-md'>
            <img src={twitter} alt='twitter' />
          </div>
          <div className='px-3 py-3 w-[35px] h-[35px] rounded-full shadow-md'>
            <img src={linkedin} alt='linkedin' />
          </div>
        </div>
      </div>
      <div className='flex justify-center px-8 pt-4 pb-6 md:pt-8 md:pb-10 md:px-16 bg-primary'>
        <p className='text-sm font-normal text-white font-open_sans'>© 2023 Copyright by Agency Solutions. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer