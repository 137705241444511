import React from 'react'
import { security } from '../assets'

function Security() {
  return (
    <div className={`bg-[#E3E3E3] px-10 py-8 md:px-16 lg:px-20 sm:py-16`}>
      <h3 className='mb-14 font-bold font-open_sans text-[40px] text-center leading-[54.47px]'>
        Data Security & Encryption with iVerifai
      </h3>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
        <div className='flex items-center col-span-1'>
          <div className='flex flex-col gap-4 text-justify'>
            <p className='text-base font-normal sm:text-[18px] font-open_sans xs:px-8 md:px-16 lg:px-28'>
              Employing advanced encryption protocols, iVerifAI safeguards all
              sensitive information throughout the verification process.
              Our AI-powered system ensures that your data is protected at all stages,
              from initial input to final storage.
            </p>
            <p className='text-base font-normal sm:text-[18px] font-open_sans xs:px-8 md:px-16 lg:px-28'>
              We implement multi-layered security measures, including end-to-end encryption,
              secure access controls, and regular security audits,
              to maintain the highest standards of data integrity and confidentiality.
            </p>
            <p className='text-base font-normal sm:text-[18px] font-open_sans xs:px-8 md:px-16 lg:px-28'>
              This commitment to security gives you peace of mind,
              knowing that your financial information is always safe.
            </p>
          </div>
        </div>
        <div className='items-center justify-end hidden col-span-1 sm:flex'>
          <img src={security} alt='img1' />
        </div>
      </div>
    </div>
  )
}

export default Security