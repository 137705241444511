import React from 'react'
import styles from '../style'
import { macBookAir1, macBookAir2, macBookAir3, macBookAir4, macBookAir5, macBookAir6 } from '../assets';

const Cards = [
  {
    title: 'Secure Portal Login',
    info: ['Log in with admin credentials, ensuring secure authentication and authorization.'],
    icon: macBookAir1,
    bg: 'bg-white'
  },
  {
    title: 'Customer Search & Profile Creation',
    info: ['Easily search for customers within the system.', 'Create new profiles when necessary, capturing essential information like name, contact details, and billing preferences.'],
    icon: macBookAir2,
    bg: 'bg-white'
  },
  {
    title: 'Rate Card Upload',
    info: ['Upload rate cards in various common formats.', 'Store rate cards securely, associating them with appropriate customer profiles.'],
    icon: macBookAir3,
    bg: 'bg-white'
  },
  {
    title: 'Invoice Verification',
    info: ['Verify invoices against uploaded rate cards.', 'Automated checks highlight discrepancies for further review.'],
    icon: macBookAir4,
    bg: 'bg-white'
  },
  {
    title: 'Exceptions Handling',
    info: ['Flag and document issues during invoice verification.', 'Add comments and attach supporting documents to resolve exceptions efficiently.'],
    icon: macBookAir5,
    bg: 'bg-white'
  },
  {
    title: 'Variance Analysis & Reporting',
    info: ['Generate detailed variance reports.', 'Drill down into variances for deeper analysis and informed decision-making.'],
    icon: macBookAir6,
    bg: 'bg-white'
  }
];

function SolutionAtGlance() {
  return (
    <div className={`bg-[#033469] ${styles.paddingX} py-6`}>
      <h3 className='font-bold text-white font-open_sans text-[40px] text-center p-4'>
        The Solution at a Glance
      </h3>
      <div className='grid grid-cols-1 px-2 py-6 lg:px-12 gap-x-8 gap-y-10'>
        {Cards.map((item, index) => (
          <div key={index} className={`flex col-span-1 ${item.bg} px-6 py-6 rounded-xl ${index % 2 === 0 ? 'md:rounded-e-full' : 'md:rounded-s-full'}`}>
            {index % 2 === 0 ? (
              <div className='grid grid-cols-1 md:grid-cols-6'>
                <div className='flex flex-col justify-center col-span-3 gap-4 lg:pl-40'>
                  <h4 className={`font-poppins font-bold text-[28px] pb-1 leading-9 text-[#002B9A]`}>
                    {item.title}
                  </h4>
                  {item.info.length <= 1 ? item.info?.map((value) => (
                    <p key={value} className={`font-open_sans font-normal text-[20px] text-left leading-[28px] text-[#666666]`}>
                      {value}
                    </p>
                  )) : (
                    <ul className='pl-8 list-disc font-open_sans font-normal text-[20px] text-left leading-[28px] text-[#666666]'>
                      {item.info?.map((value) => (
                        <li key={value} >{value}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className='hidden col-span-3 pr-10 md:block'>
                  <img src={item.icon} alt='img2' />
                </div>
              </div>
            ) : (
              <div className='grid grid-cols-1 md:pl-32 md:grid-cols-6'>
                <div className='hidden col-span-3 md:block'>
                  <img src={item.icon} alt='img2' />
                </div>
                <div className='flex flex-col justify-center col-span-3 gap-4 lg:pr-40'>
                  <h4 className={`font-poppins font-bold text-[28px] pb-1 leading-9 text-[#002B9A]`}>
                    {item.title}
                  </h4>
                  {item.info.length <= 1 ? item.info?.map((value) => (
                    <p key={value} className={`font-open_sans font-normal text-[20px] text-left leading-[28px] text-[#666666]`}>
                      {value}
                    </p>
                  )) : (
                    <ul className='pl-8 list-disc font-open_sans font-normal text-[20px] text-left leading-[28px] text-[#666666]'>
                      {item.info?.map((value) => (
                        <li key={value} >{value}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SolutionAtGlance